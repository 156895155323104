import React from 'react';
import BottomElement from './bottomElement';
import globalConfig from '../../../modules/global';
import { dateFormatter, obeFormat } from '../../resorts/rooms/utils/dateUtils';
import getCookie from '../../../modules/includes/get-cookie';
import { injectData } from '~/js/modules/includes/data-layer-handler.js';

const MainButton = props => {
  const { DEFAULTS } = globalConfig;
  const { obeURL } = DEFAULTS;

  const {
    copy,
    bottomElement,
    vacationData,
    horizontal = false,
    buttonAction,
    targetBlank,
    customCallback,
    withGroup = false,
    resortOptions = [],
    lastMinuteDeals = false,
    withFlightsVisible = false,
    isResortRequired = false,
    isDatesRequired = false,
  } = props;

  const actionHandler = url => {
    if (targetBlank) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  };

  const clickHandler = event => {
    const dataLayer = {
      vacationData: vacationData,
      resortOptions: resortOptions,
    };

    if (window.location.href.includes('/detail/?categoryCode=')) {
      injectData('booknow_room', dataLayer);
    } else {
      injectData('search_site', dataLayer);
    }

    if (!customCallback) {
      const {
        referral,
        agentId,
        rstCode,
        categoryCode,
        checkInDate,
        checkOutDate,
        gateway,
        seatType,
        arrivalGateway,
      } = vacationData;

      const obeCheckIn = dateFormatter(checkInDate, 'MM-DD-YYYY', obeFormat);
      const obeCheckOut = dateFormatter(checkOutDate, 'MM-DD-YYYY', obeFormat);

      const datesChecked = checkInDate && checkOutDate;
      const urlParams = new URLSearchParams(window.location.search);
      const utmMedium = urlParams.get('utm_medium');
      const utmSource = urlParams.get('utm_source');
      const utmContent = urlParams.get('utm_content');
      const utmCampaign = urlParams.get('utm_campaign');
      const cjEvent = urlParams.get('cjevent');

      const obePartnerRef = getCookie('obe_partner_ref');

      const params = `?NOOFADULTS=2&NOOFCHILDREN=0${
        referral ? `&referral=${referral}` : ''
      }${
        agentId ? `&agentid=${agentId}` : ''
      }&CAT_CODE=${categoryCode}&RST_CODE=${rstCode}&STARTDATE=${
        datesChecked ? obeCheckIn : ''
      }&DEPARTUREDATE=${datesChecked ? obeCheckOut : ''}&COOKIENAME=quickquote${
        datesChecked && rstCode && !withGroup && !lastMinuteDeals
          ? '&BYPASSFORM=1'
          : datesChecked && rstCode && !withGroup && lastMinuteDeals
          ? '&BYPASSFORM=2&ireferrer=SV13'
          : ''
      }&brand=sandals&EVENT=ehOBE.dspHome&DEPARTURE_FROM=${gateway}&ARRIVAL_GATEWAY=${
        withFlightsVisible ? arrivalGateway : ''
      }&SEATTYPE=${seatType}${utmMedium ? `&utm_medium=${utmMedium}` : ''}${
        utmSource ? `&utm_source=${utmSource}` : ''
      }${utmContent ? `&utm_content=${utmContent}` : ''}${
        utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
      }${cjEvent ? `&cjevent=${cjEvent}` : ''}${
        obePartnerRef ? '&channel=cj1' : ''
      }${obePartnerRef ? '&obe_partner_code=CJ' : ''}${
        obePartnerRef ? '&obe_partner_ref=true' : ''
      }&SV13`;

      let url = buttonAction ? buttonAction : `${obeURL}${params}`;

      const clickedButton = event.target;
      const sessionDatesChecked = checkInDate && checkOutDate;

      const mainElement = clickedButton.closest('.qq-wrapper');

      if (
        (!rstCode ||
          !sessionDatesChecked ||
          (withFlightsVisible && !gateway)) &&
        (isDatesRequired || isResortRequired)
      ) {
        if (!rstCode) {
          const resortSelect = mainElement.querySelector('.qq-resort-select');

          if (!resortSelect.classList.contains('is-invalid')) {
            resortSelect.classList.toggle('is-invalid');
          }
        }

        if (!sessionDatesChecked) {
          const mainCalendar = mainElement.querySelector('.qq-calendar');

          const calendarSelect =
            mainCalendar.querySelector('.san-form-group') !== null
              ? mainCalendar.querySelector('.san-form-group')
              : mainCalendar.closest('.san-form-group');

          if (!calendarSelect.classList.contains('is-invalid')) {
            calendarSelect.classList.toggle('is-invalid');
          }
        }

        if (withFlightsVisible && gateway === '') {
          const mainDepartureSelect =
            mainElement.querySelectorAll('.flights-departing');

          [...mainDepartureSelect].map(item => {
            if (!item.classList.contains('is-invalid')) {
              item.classList.toggle('is-invalid');
            }
          });
        }

        return;
      }

      if (typeof buttonAction === 'function') {
        url = buttonAction();
      }

      actionHandler(url);
    } else {
      customCallback();
    }
  };

  const size = horizontal ? 'xs-12 sm-11' : 'xs-12';

  return (
    <>
      <div className="row center-xs middle-xs qq-main-btn">
        <button className={`${size}`} onClick={event => clickHandler(event)}>
          {copy}
        </button>
      </div>
      {bottomElement && <BottomElement>{bottomElement}</BottomElement>}
    </>
  );
};

export default MainButton;
