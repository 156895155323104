import m from 'moment';

const todaysDate = m();
const globalConfig = {};
const { env } = window.sandals_app;

globalConfig.ACTIVE_RATES = window.sandals_app.global.settings.active_rates;

globalConfig.DEFAULTS = {
  minimumNights: 1,
  minDate: m(todaysDate, 'MM-DD').add(1, 'days'),
  maxDate: m('2027-01-01', 'YYYY-MM-DD'),
  minDateSDR: m(todaysDate, 'MM-DD').add(1, 'days'),
  minDateSSV: m('03-27-2024', 'MM-DD-YYYY', true),
  maximumNights: 21,
  children: 0,
  infants: 0,
  ssgNumber: 0,
  seatType: 'economy',
  adults: 2,
  rooms: 1,
  airIncluded: false,
  resortCode: sessionStorage.getItem('generalRstCode'),
  obeURL:
    env === 'development'
      ? 'http://qobe.sandals.com'
      : env === 'STAGING'
      ? 'https://stgobe.sandals.com/'
      : 'https://obe.sandals.com/',
};

export default globalConfig;
