import React, { useState, useEffect } from 'react';
import m from 'moment';

import ResortSelect from '../resortSelect';
import Calendar from '../calendar';
import MainButton from '../mainButton';
import WithFlightsElement from '../withFlightsElement';
import FlightsElement from '../flightsElement';
import globalConfig from '../../../../modules/global';

const SpecificDatesHorizontalRegular = props => {
  const { DEFAULTS } = globalConfig;
  const {
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,

      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
    },
    resortSelect = {
      resortOptions: [],
      isResortRequired: false,
    },
    saveVacationData,
    vacationData,
    resetQQ = false,
    withFlightsId,
    calendarWrapperClassName = '',
    withFlightsWrapperClassName = '',
  } = props;

  const { resortOptions, isResortRequired } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    minDate,
    maxDate,
    minimumNights,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    isDatesRequired = false,
  } = calendar;

  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [airportArrivingTo, setAirportArrivingTo] = useState('ocj');
  // const [size, setSize] = useState([0, 0]);
  const arrivalGatewayResorts = ['SGO', 'BRP', 'SDR'];

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const toggleWithFlights = () => {
    const seatType = withFlightsVisible ? '' : 'economy';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({ gateway: '', seatType });

    setAirportArrivingTo(
      arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
        ? 'ocj'
        : ''
    );
    saveVacationData({
      arrivalGateway:
        arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
          ? 'ocj'
          : '',
    });
  };

  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handleAirportArrivingTo = e => {
    const { currentTarget } = e;
    const { value: arrivalGateway } = currentTarget;

    setAirportArrivingTo(arrivalGateway);
    saveVacationData({ arrivalGateway });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };

  useEffect(() => {
    if (resetQQ) {
      setWithFlightsVisible(false);
      setSearchCriteria('');

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        gateway: '',
        seatType: 'economy',
        arrivalGateway: '',
      });
    }
  }, [resetQQ]);

  return (
    <div className="row" style={{ paddingTop: '1.5rem' }}>
      <div className="xs-12">
        <div className="row center-xs top-xs">
          {resortOptions.length > 0 && (
            <div className="xs-12 sm-4 md-3 qq-element-container">
              <div className="qq-element-wrap qq-resort-select--custom-size">
                <ResortSelect
                  alignment="horizontal"
                  resortOptions={resortOptions}
                  vacationData={vacationData}
                  saveVacationData={saveVacationData}
                  onInputClick={onInputClick}
                  resetInput={resetQQ}
                  setAirportArrivingTo={setAirportArrivingTo}
                  isRequired={isResortRequired}
                  withFlightsVisible={withFlightsVisible}
                  arrivalGatewayResorts={arrivalGatewayResorts}
                />
                <div className="qq-element-wrap no-gutters-sm-min-up hidden-sm-min-down hidden-sm-max-up">
                  <WithFlightsElement
                    id={withFlightsId[0]}
                    value={withFlightsVisible}
                    onClick={toggleWithFlights}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={`xs-12 sm-4 md-4 ${
              monthsToDisplay > 0 ? '' : 'hidden-xs-min-up'
            }`}
          >
            <div
              className={`qq-element-wrap qq-calendar qq-calendar--custom-size ${calendarWrapperClassName}`}
            >
              <Calendar
                defaultText={defaultText}
                iconCaret={iconCaret}
                iconCalendar={iconCalendar}
                saveInputDate={saveInputDate}
                theme={calendarTheme}
                checkinDateName={checkinDateName}
                checkoutDateName={checkoutDateName}
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={minimumNights}
                monthsToDisplay={monthsToDisplay}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                alignment="float"
                onApplyDates={onApplyDates}
                onClearDates={onClearDates}
                onCloseCalendar={onCloseCalendar}
                resetInput={resetQQ}
                resortOptions={resortOptions}
                withFlightsVisible={withFlightsVisible}
                isRequired={isDatesRequired}
                setAirportArrivingTo={setAirportArrivingTo}
                arrivalGatewayResorts={arrivalGatewayResorts}
              />
            </div>
          </div>

          <div className="xs-12 md-2 no-gutters-sm-min-up hidden-sm-min-up main-row-with-flights-element">
            <div className={`qq-element-wrap ${withFlightsWrapperClassName}`}>
              <WithFlightsElement
                id={withFlightsId[1]}
                value={withFlightsVisible}
                onClick={toggleWithFlights}
              />
            </div>
          </div>
          {withFlightsVisible && (
            <div className="xs-12 hidden-xs-max-up">
              <div className="row left-xs top-xs">
                <FlightsElement
                  searchCriteria={searchCriteria}
                  handleSearchCriteriaChange={handleSearchCriteriaChange}
                  selectGateway={selectGateway}
                  resetInput={resetQQ}
                  flightsElementClassName="xs-12 sm-4 flights-departing"
                  searchCriteriaClassName="xs-12 sm-4 flight-search-criteria qq-resort-select"
                  airportArrivingToClassName="xs-12 sm-4 airport-arriving qq-resort-select"
                  handleAirportArrivingTo={handleAirportArrivingTo}
                  airportArrivingTo={airportArrivingTo}
                  setAirportArrivingTo={setAirportArrivingTo}
                  vacationData={vacationData}
                  arrivalGatewayResorts={arrivalGatewayResorts}
                  isRequired
                />
              </div>
            </div>
          )}
          {mainButton && (
            <div className="xs-12 sm-4 md-3 qq-bottom-background">
              <div className="qq-element-wrap">
                <MainButton
                  copy={mainButton}
                  bottomElement={bottomElement}
                  vacationData={vacationData}
                  buttonAction={buttonAction}
                  targetBlank={targetBlank}
                  customCallback={customCallback}
                  horizontal
                  resortOptions={resortOptions}
                  withFlightsVisible={withFlightsVisible}
                  isResortRequired={isResortRequired}
                  isDatesRequired={isDatesRequired}
                />
              </div>
            </div>
          )}
        </div>
        {withFlightsVisible && (
          <div className="row left-xs top-xs hidden-xs-max-down">
            <div className="xs-12">
              <div className="row left-xs top-xs airport-arriving--margin">
                <FlightsElement
                  searchCriteria={searchCriteria}
                  handleSearchCriteriaChange={handleSearchCriteriaChange}
                  selectGateway={selectGateway}
                  resetInput={resetQQ}
                  flightsElementClassName="xs-12 sm-6 lg-4 flights-departing"
                  searchCriteriaClassName="xs-12 sm-6 lg-4 qq-resort-select"
                  airportArrivingToClassName="xs-12 sm-6 lg-4 airport-arriving qq-resort-select"
                  handleAirportArrivingTo={handleAirportArrivingTo}
                  airportArrivingTo={airportArrivingTo}
                  setAirportArrivingTo={setAirportArrivingTo}
                  vacationData={vacationData}
                  arrivalGatewayResorts={arrivalGatewayResorts}
                  isRequired
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecificDatesHorizontalRegular;
