import React from 'react';
import { render } from 'react-dom';

import _ from '~/js/modules/utils';
import { replacePhoneElement } from '~/js/modules/includes/replace-phone';
import SubscribeAndSave from '~/js/components/global/subscription/SubscribeAndSave';
import UkVisitor from '~/js/components/global/uk-visitor/UkVisitor';

import HeaderV2 from '~js/components/global/layout/HeaderV2';
import Header from '~js/components/global/layout/Header';

const { headerData = {} } = window.sandals_app.page;

const ABTestIDHeader = sessionStorage.getItem('ABTestIDHeader');

const headerLayout = ABTestIDHeader ? 0 : 2;

const groupsNum = document.querySelectorAll(
  '#js-header #groups .items__extra a'
);

replacePhoneElement(
  ['1-800-327-1991 (EXT 6172)', '18887263257'],
  [...groupsNum]
);

const countryList =
  window.sandals_app.global.layout[headerLayout]?.resortsByCountry;

const accountsInformation =
  window.sandals_app.global.layout[headerLayout].miscellaneous[2].items;

const getResorts = () => {
  const result = [];

  countryList &&
    countryList.map(country =>
      country.resortsList.map(resort => {
        result.push({
          country: {
            name: country.countryShortName,
            code: country.countryCode,
          },
          shortName: resort.name,
          code: resort.code,
          url: resort.url,
        });
      })
    );

  return result;
};

const resorts = getResorts();

const renderHeader = () => {
  return ABTestIDHeader ? (
    <HeaderV2
      headerData={headerData?.headerV2Data}
      resorts={resorts}
      accountsInformation={accountsInformation}
      requestURI={window.sandals_app.requestURI}
    />
  ) : (
    <Header
      headerData={headerData?.headerV1Data}
      resorts={resorts}
      accountsInformation={accountsInformation}
      requestURI={window.sandals_app.requestURI}
    />
  );
};

const header = () => {
  render(
    <SubscribeAndSave requestURI={window.sandals_app.requestURI} />,
    document.getElementById('js-subscribe-and-save-popup')
  );

  render(<UkVisitor />, document.getElementById('js-uk-visitor-popup'));

  const headerElement = document.getElementById('js-header-container');

  render(renderHeader(), headerElement);
};

header();

export default header;
