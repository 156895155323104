import React, { useState, useRef, useEffect } from 'react';
import m from 'moment';
import ResortOptions from './resortOptions';

const ResortSelect = props => {
  const {
    resortOptions,
    saveVacationData,
    labelText,
    onInputClick,
    value = null,
    resetInput,
    className,
    vacationData,
    setAirportArrivingTo = null,
    isRequired = false,
    withFlightsVisible = false,
    arrivalGatewayResorts = [],
  } = props;
  const regions = [];

  resortOptions.map(resort => {
    const { country } = resort;
    const { code, name } = country;
    const found = regions.filter(region => region.country.code === code);
    const resorts = resortOptions.filter(res => res.country.code === code);
    const regionElement = {
      country: {
        code,
        name,
      },
      resorts,
    };

    if (found.length < 1) {
      regions.push(regionElement);
    }
  });

  const [selectedLabel, setSelectedLabel] = useState(
    labelText || 'Select Resort'
  );
  const [optionsVisible, setOptionsVisible] = useState(false);

  const toggleOptionsVisible = () => {
    setOptionsVisible(!optionsVisible);
    if (onInputClick) {
      onInputClick('resort-select');
    }
  };
  const closeOptions = () => {
    setOptionsVisible(false);
  };

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const setValue = e => {
    const rstCode = e.currentTarget.getAttribute('data-value');
    const rstShortName = e.currentTarget.getAttribute('data-label');

    setSelectedLabel(rstShortName);
    toggleOptionsVisible();

    saveVacationData({ rstCode });

    if (setAirportArrivingTo) {
      setAirportArrivingTo(
        arrivalGatewayResorts.indexOf(rstCode) !== -1 &&
          getValidDates() &&
          withFlightsVisible
          ? 'ocj'
          : ''
      );
      saveVacationData({
        arrivalGateway:
          arrivalGatewayResorts.indexOf(rstCode) !== -1 &&
          getValidDates() &&
          withFlightsVisible
            ? 'ocj'
            : '',
        rstCode,
      });
    }

    if (rstCode !== '') {
      const selectedElement = e.currentTarget;
      const closestSelect = selectedElement.closest('.san-form-group');

      if (closestSelect?.classList?.contains('is-invalid')) {
        closestSelect?.classList?.toggle('is-invalid');
      }
    }
  };

  const optionsRef = useRef(null);

  const handleClick = e => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      closeOptions();
    }
  };

  const registerMouseDown = () => {
    document.addEventListener('mousedown', handleClick);
  };

  const unRegisterMouseDown = () => {
    document.removeEventListener('mousedown', handleClick);
  };

  const setDefoValue = val => {
    if (val && val !== '') {
      const found = resortOptions.find(
        resortInfo => resortInfo.code.toLowerCase() === val.toLowerCase()
      );

      if (found) {
        setSelectedLabel(found.shortName);
      }
    } else if (typeof val !== 'undefined' && val === '') {
      setSelectedLabel(labelText || 'Select Resort');
    }
  };

  function resetElement() {
    if (resetInput) {
      setSelectedLabel(labelText || 'Select Resort');
      closeOptions();
    }
  }

  useEffect(() => {
    registerMouseDown();

    return () => {
      unRegisterMouseDown();
    };
  }, []);

  useEffect(() => {
    setDefoValue(value);
  }, [value]);

  useEffect(() => {
    resetElement();
  }, [resetInput]);

  return (
    <div
      className={`row center-xs bottom-xs qq-resort-select ${
        isRequired ? 'san-form-group' : ''
      }`}
    >
      <div className="xs-12 qq-input-wrapper">
        <div className={'select-wrapper custom-select'} ref={optionsRef}>
          <div
            className={`qq-input${className ? ` ${className}` : ''} ${
              selectedLabel === 'Select Resort / Destination'
                ? 'qq-input--placeholder'
                : ''
            } ${isRequired ? 'san-input' : ''}`}
            onClick={toggleOptionsVisible}
          >
            <span>{selectedLabel}</span>
          </div>
          {optionsVisible && (
            <ul className="option-list mega-menu">
              {regions.map((region, i) => {
                return (
                  <ResortOptions
                    key={i}
                    region={region}
                    onClick={setValue}
                    vacationData={vacationData}
                  />
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {isRequired && (
        <div className="xs-12">
          <div className="has-error">
            <span className="san-feedback">This field is required.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResortSelect;
