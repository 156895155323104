const searchBar = () => {
  const headerEl = document.getElementById('js-header');
  const searchBarEl = headerEl.querySelector('.search__bar');
  const searchBtn = headerEl.querySelector('.search__button');
  const redirectUrl = '/search/?searchTerm=';

  const redirectToSearch = () => {
    const searchTerm = encodeURI(searchBarEl.value);

    if (searchTerm.length > 0) {
      window.location.href = `${redirectUrl}${searchTerm}`;
    }
  };

  searchBarEl.addEventListener('keyup', e => {
    if (e.keyCode === 13) {
      redirectToSearch();
    }
  });

  searchBtn.addEventListener('click', () => redirectToSearch());
};

export default searchBar;
