import React, { useState, useEffect } from 'react';
import m from 'moment';

import ResortSelect from '../resortSelect';
import Calendar from '../calendar';
import MainButton from '../mainButton';
import WithFlightsElement from '../withFlightsElement';
import FlightsElement from '../flightsElement';
import globalConfig from '../../../../modules/global';

const SpecificDatesHorizontalSpecial = props => {
  const { DEFAULTS } = globalConfig;

  const {
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,
      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
      isResortRequired: false,
    },
    saveVacationData,
    vacationData,
    resetQQ = false,
    horizontalSpecialAlignment = false,
    withFlightsId,
  } = props;

  const { resortOptions, labelText, isResortRequired } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    minDate,
    maxDate,
    minimumNights,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    defoCheckoutDate,
    defoCheckinDate,
    isDatesRequired = false,
  } = calendar;

  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [airportArrivingTo, setAirportArrivingTo] = useState('ocj');
  // const [size, setSize] = useState([0, 0]);
  const arrivalGatewayResorts = ['SGO', 'BRP', 'SDR'];

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const toggleWithFlights = () => {
    const seatType = withFlightsVisible ? '' : 'economy';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({ gateway: '', seatType });

    setAirportArrivingTo(
      arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
        ? 'ocj'
        : ''
    );
    saveVacationData({
      arrivalGateway:
        arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
          ? 'ocj'
          : '',
    });
  };

  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handleAirportArrivingTo = e => {
    const { currentTarget } = e;
    const { value: arrivalGateway } = currentTarget;

    setAirportArrivingTo(arrivalGateway);
    saveVacationData({ arrivalGateway });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };

  useEffect(() => {
    if (resetQQ) {
      setWithFlightsVisible(false);
      setSearchCriteria('');

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        gateway: '',
        seatType: 'economy',
        arrivalGateway: '',
      });
    }
  }, [resetQQ]);

  const activeABTest = sessionStorage.getItem('ABTestID');
  const actualRstCode = vacationData.rstCode;

  return (
    <>
      <div
        className="row special-horizontal-qq"
        style={{ paddingTop: '3.5rem' }}
      >
        <div className="xs-12">
          <div className="row hidden-sm-max-down">
            <div className="xs-12">
              <em className="specific-travel-dates-text">
                SPECIFIC TRAVEL DATES
              </em>
            </div>
          </div>
          <div
            className={`row center-xs top-xs ${
              withFlightsVisible ? '' : 'middle-sm'
            } top-md`}
          >
            <div className="xs-12 sm-6 md-9">
              <div className="row">
                {resortOptions.length > 0 && (
                  <div className="xs-12 md-4">
                    <em className="specific-travel-dates-text hidden-sm-max-up">
                      SPECIFIC TRAVEL DATES
                    </em>
                    <div className="qq-element-wrap">
                      <ResortSelect
                        alignment="horizontal"
                        resortOptions={resortOptions}
                        labelText={labelText}
                        vacationData={vacationData}
                        saveVacationData={saveVacationData}
                        onInputClick={onInputClick}
                        resetInput={resetQQ}
                        setAirportArrivingTo={setAirportArrivingTo}
                        isRequired={isResortRequired}
                        withFlightsVisible={withFlightsVisible}
                        arrivalGatewayResorts={arrivalGatewayResorts}
                      />
                    </div>
                  </div>
                )}
                {activeABTest === '1086047' && actualRstCode === 'SSV' && (
                  <div className="home-ssv-note--mobile">
                    <div className="xs-12">
                      <p className="home-ssv-note">
                        Best arrival + departure flights: USA: Mon, Wed, Sat.
                        CANADA: Mon, Tue, Thu and Fri.
                      </p>
                    </div>
                  </div>
                )}
                {monthsToDisplay > 0 && (
                  <div
                    className={`xs-12 md-8 special-qq-calendar ${
                      monthsToDisplay > 0 ? '' : 'hidden-xs-min-up'
                    }`}
                  >
                    <div className="qq-element-wrap">
                      <Calendar
                        defaultText={defaultText}
                        iconCaret={iconCaret}
                        iconCalendar={iconCalendar}
                        theme={calendarTheme}
                        checkinDateName={checkinDateName}
                        checkoutDateName={checkoutDateName}
                        minDate={minDate}
                        maxDate={maxDate}
                        minimumNights={minimumNights}
                        monthsToDisplay={monthsToDisplay}
                        vacationData={vacationData}
                        saveVacationData={saveVacationData}
                        onInputClick={onInputClick}
                        alignment="left"
                        onApplyDates={onApplyDates}
                        onClearDates={onClearDates}
                        onCloseCalendar={onCloseCalendar}
                        resetInput={resetQQ}
                        horizontalSpecialAlignment={horizontalSpecialAlignment}
                        resortOptions={resortOptions}
                        defoCheckoutDate={defoCheckoutDate}
                        defoCheckinDate={defoCheckinDate}
                        withFlightsVisible={withFlightsVisible}
                        isRequired={isDatesRequired}
                        setAirportArrivingTo={setAirportArrivingTo}
                        arrivalGatewayResorts={arrivalGatewayResorts}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {mainButton && (
              <div className="xs-12 sm-6 md-3">
                <div className="row right-side-flights-special hidden-sm-max-up">
                  <div className="xs-12">
                    <div className="qq-element-wrap">
                      <WithFlightsElement
                        id={withFlightsId[0]}
                        value={withFlightsVisible}
                        onClick={toggleWithFlights}
                        labelCopy="Include Roundtrip Flights"
                      />
                    </div>
                  </div>
                  <div className="xs-12">
                    {withFlightsVisible && (
                      <div className="row left-xs top-xs middle-sm">
                        <FlightsElement
                          searchCriteria={searchCriteria}
                          handleSearchCriteriaChange={
                            handleSearchCriteriaChange
                          }
                          selectGateway={selectGateway}
                          resetInput={resetQQ}
                          flightsElementClassName="xs-12 flights-departing"
                          searchCriteriaClassName="xs-12 qq-resort-select search-criteria-wrapper"
                          airportArrivingToClassName="xs-12 qq-resort-select arriving-to-airport-wrapper"
                          handleAirportArrivingTo={handleAirportArrivingTo}
                          airportArrivingTo={airportArrivingTo}
                          setAirportArrivingTo={setAirportArrivingTo}
                          vacationData={vacationData}
                          arrivalGatewayResorts={arrivalGatewayResorts}
                          isRequired
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="qq-element-wrap">
                  <MainButton
                    copy={mainButton}
                    bottomElement={bottomElement}
                    vacationData={vacationData}
                    buttonAction={buttonAction}
                    targetBlank={targetBlank}
                    customCallback={customCallback}
                    horizontal
                    resortOptions={resortOptions}
                    withFlightsVisible={withFlightsVisible}
                    isResortRequired={isResortRequired}
                    isDatesRequired={isDatesRequired}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {activeABTest === '1086047' && actualRstCode === 'SSV' && (
        <div className="home-ssv-note--desktop">
          <div className="xs-12">
            <p className="home-ssv-note">
              Best arrival + departure flights: USA: Mon, Wed, Sat. CANADA: Mon,
              Tue, Thu and Fri.
            </p>
          </div>
        </div>
      )}
      <div className="row hidden-sm-max-down special-bottom-flights-selector">
        <div className="xs-12 sm-3">
          <div className="qq-element-wrap">
            <WithFlightsElement
              id={withFlightsId[1]}
              value={withFlightsVisible}
              onClick={toggleWithFlights}
              labelCopy="Include Roundtrip Flights"
            />
          </div>
        </div>
        <div className="xs-12 sm-9">
          {withFlightsVisible && (
            <div className="row left-xs top-xs">
              <FlightsElement
                searchCriteria={searchCriteria}
                handleSearchCriteriaChange={handleSearchCriteriaChange}
                selectGateway={selectGateway}
                resetInput={resetQQ}
                flightsElementClassName="xs-12 sm-6 lg-4 flights-departing"
                searchCriteriaClassName="xs-12 sm-6 lg-4 qq-resort-select"
                airportArrivingToClassName="xs-12 sm-6 lg-4 airport-arriving qq-resort-select"
                handleAirportArrivingTo={handleAirportArrivingTo}
                airportArrivingTo={airportArrivingTo}
                setAirportArrivingTo={setAirportArrivingTo}
                vacationData={vacationData}
                arrivalGatewayResorts={arrivalGatewayResorts}
                isRequired
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SpecificDatesHorizontalSpecial;
